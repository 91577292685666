<template>
	<el-dialog
		title="添加考勤人员"
		:close-on-click-modal="false"
		:visible.sync="visible"
		width="1000px">
		<el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="80px" :inline="true">
			<el-row>
				<el-col :span="24">
					<el-form-item label="月份" prop="attendanceMonth">
						<el-date-picker
						  v-model="dataForm.attendanceMonth"
						  @change="getDetail()"
						  type="month"
						  value-format="yyyy-MM"
						  placeholder="选择月">
						</el-date-picker>
					</el-form-item>
				</el-col>
				<el-col :span="24">
					<el-form-item label="人员" prop="attendanceMonth">
					  <el-checkbox-group v-model="selUserList">
						<el-checkbox :label="item.recId" name="type" v-for="(item,index) in userList" @change="setUserSel(index)">{{item.personName}}</el-checkbox>
					  </el-checkbox-group>
					  </el-form-item>
				    </el-col>
			</el-row>
			  <el-row class="formBtnGroup">
				<el-button v-preventReClick class="primaryPlainBtn" type="primary" plain @click="visible = false">取消</el-button>
				<el-button v-preventReClick class="primaryPlainBtn" type="primary" @click="dataFormSubmit()">确定</el-button>
			  </el-row>
		</el-form>
	</el-dialog>
</template>

<script>
	import $common from "@/utils/common.js"
	export default {
	  name: "leavetime-add-or-update",
	  data() {
	    return {
			visible: true,
			form:{},
			action:"",
			selUserList:[],
			userList:[],
			dataForm:{
				attendanceMonth:""
			},
			dataRule: {
				attendanceMonth: [
					{ required: true, message: "请选择考勤月份！", trigger: "blur" }
				]
			},
	    };
	  },
	  components: {

	  },
	  activated() {

	  },
	  methods: {
		init() {
			this.dataForm = {
				attendanceMonth:"",
				userId:[]
			};
			this.getDetail();
		    this.$nextTick(() => {
		    	this.visible = true;
		    })
		},
		getDetail(){
			this.$http({
			  url: this.$store.state.httpUrl + "/business/attendanceinfo/initAttendancePerson",
			  method: "POST",
			  data: {
				attendanceMonth: this.dataForm.attendanceMonth,
				subjectNo: $common.getItem("subjectNo"),
				workNo: $common.getItem("workNo"),
				limit:"",
				page:""
			  },
			}).then(({ data }) => {
			  if (data && data.resultCode === 200) {
				this.dataForm.attendanceMonth = data.body.attendanceMonth;
				let _userList = data.body.records;
				this.userList = _userList;
				let _selUserList = [];
				_userList.forEach((item)=>{
					if(item.attendanceStatus == '1'){
						_selUserList.push(item.recId)
					}
				})
				// console.log(_selUserList)
				this.selUserList = _selUserList;
			  }
			});
		},
		setUserSel(index){
			let _userList = this.userList;
			_userList[index].attendanceStatus = _userList[index].attendanceStatus == "0"?"1":"0";
			this.userList = _userList;
		},
		dataFormSubmit() {
		  this.$refs["dataForm"].validate(valid => {
		    if (valid) {
		      this.$http({
		        url: this.$store.state.httpUrl + "/business/attendanceinfo/saveAttendancePerson",
		        method: "post",
		        data: {
					attendanceMonth: this.dataForm.attendanceMonth,
					personRecords: this.userList,
					subjectNo: $common.getItem("subjectNo"),
					workNo: $common.getItem("workNo"),
				}
		      }).then(({ data }) => {
		        if (data && data.resultCode === 200) {
		          this.$message({
		            message: "操作成功",
		            type: "success",
		            duration: 1500,
		            onClose: () => {
		              this.visible = false;
		              this.$emit("refreshDataList");
		            }
		          });
		        } else {
		          this.$message.error(data.msg);
		        }
		      });
		    }
		  });
		}
	  }
	};
</script>

<style lang="scss" scoped="scoped">
	.selItemInput.descAreaLg{
		width: 718px !important;
	}
	.addImgBox{
		width: 136px;
		height: 136px;
		display: inline-block;
		vertical-align: middle;
		margin-right: 10px;
	}
</style>
